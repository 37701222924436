import React, { useEffect, useState } from 'react'
import GeneralContainer from '../../globalParams/GeneralContainer'
import { RequestAuthPaginated } from '../../../http/httpRequest'
import { DefaultTable } from '../../../components/table/DefaultTable'
import { useDispatch, useSelector } from 'react-redux'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { AlmacenTab } from './AlmacenTab'
import { ModalForm } from '../../../components/modal/ModalForm'
import { EditarClienteForm, EliminarClienteForm } from './ClienteTabFormDatas'
import { ClienteTabGeneralButtons } from './ClienteTabGeneralButtons'
import Loading from '../../globalParams/Loading'

export const AlmacenBoard = () => {
    const dispatch = useDispatch();
    const globalParams = useSelector(state => state.globalParams)
    const user = useSelector(state => state.auth)
    console.log(user)
    const [dataClientes, setDataClientes] = useState({
        data: [],
        recall: true,
        nav: true,
        navParam: '',
        navRef: 'id',
        loading: false,
        subId: 'clientId',
        params: {
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            pageSize: 25,
            // nombres: ''
            filterBy: 'nombres',
            filterParam: ''
        },
        filters: ['nombres', 'codigo'],
        selected: null,
        modalCrearCliente: false,
        modalEditarCliente: false,
        modalEliminarCliente: false
    })

    const getClientes = async () => {
        await RequestAuthPaginated(
            'get',
            `/almacen/${user.id}/productos/pag`,
            setDataClientes,
            'data',
            dispatch,
            dataClientes.params
        )
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataClientes.recall, dataClientes?.params?.pageSize, dataClientes?.params?.currentPage, dataClientes.params?.nombres, dataClientes.params?.filterParam, dataClientes.params?.filterBy, globalParams.recall1, user?.id])

    if (user?.id === 0)
        return <Loading />

    return (
        <GeneralContainer>
            <ClienteTabGeneralButtons
                setDataClientes={setDataClientes}
                modals={{ modalCrearCliente: dataClientes.modalCrearCliente }}
            />
            <DefaultTable
                data={dataClientes}
                setData={setDataClientes}
                headers={[
                    {
                        label: 'Producto',
                        value: ['nombreProducto'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Tipo',
                        value: ['tipoProducto'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Stock',
                        value: ['cantidad'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                        alignRight: true,
                    },
                    // {
                    //     label: 'Acciones',
                    //     type: 'acciones',
                    //     actions: [
                    //         {
                    //             adCNButton: 'warning',
                    //             adCNIcon: 'pen-to-square',
                    //             title: 'Editar',
                    //             func: (item) => setDataClientes(x => ({ ...x, modalEditarCliente: true })),
                    //         },
                    //         {
                    //             adCNButton: 'danger',
                    //             adCNIcon: 'trash',
                    //             title: 'Borrar',
                    //             func: () => {
                    //                 setDataClientes(x => ({ ...x, modalEliminarCliente: true }))
                    //             },
                    //         },
                    //     ]
                    // }
                ]}
            />
            <Routes>
                <Route
                    path=':almProdId/*'
                    element={
                        <AlmacenTab
                            setData={setDataClientes}
                            data={{ recall: dataClientes?.recall, selected: dataClientes?.selected, subId: dataClientes?.subId }}
                        />
                    } />
            </Routes>
            {
                dataClientes.modalEditarCliente &&
                <ModalForm
                    show={dataClientes.modalEditarCliente}
                    onHide={() => setDataClientes(x => ({ ...x, modalEditarCliente: false }))}
                    urlApi={'/userCliente'}
                    method='put'
                    formData={EditarClienteForm(dataClientes.selected)}
                />
            }
            {
                dataClientes.modalEliminarCliente &&
                <ModalForm
                    show={dataClientes.modalEliminarCliente}
                    onHide={() => setDataClientes(x => ({ ...x, modalEliminarCliente: false }))}
                    urlApi={'/userCliente'}
                    method='delete'
                    formData={EliminarClienteForm(dataClientes.selected)}
                    back={true}
                />
            }
        </GeneralContainer>
    )
}
