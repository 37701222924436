import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import MapView from '../../../components/maps/MapView'
import ModalConfirmation from '../../../components/modal/ModalConfirmation'
import { GetClientLocationsApi } from '../../../http/Services/ClientApi'
import { CreateNoteApi, GetMyCurrentNoteApi } from '../../../http/Services/NoteApi'
import { noteAssignedAction } from '../../../redux/action/authAction'
import { toastToggleAction } from '../../../redux/action/toastAction'
import MapView from '../adminAccount/map/MapView'

import CardClient from './CardClient'
import CardSeller from './CardSeller'
import CardAdmin from './CardAdmin'

export default function Home() {
    const user = useSelector(state => state.auth)
    const [currentNote, setCurrentNote] = useState(null)
    const [cardState, setCardState] = useState('load')
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [recall, setRecall] = useState(false)
    const [clients, setClients] = useState([])


    const dispatch = useDispatch()

    useEffect(() => {
        const getCurrentNote = async () => {
            try {
                let res = await GetMyCurrentNoteApi();
                setCurrentNote(res.data)
                if (res.data.id) {
                    setCardState('show')
                }
                else {
                    setCardState('button')
                }
            } catch (error) {
                console.log("error al traer el cuaderno actual")
            }
        }
        getCurrentNote()
    }, [recall])

    const handleCloseConfirmation = () => setModalConfirmation(false)
    const handleShowConfirmation = () => {
        setModalConfirmation(true);
    }


    const createNote = async () => {
        try {
            setButtonDisabled(true)
            let res = await CreateNoteApi()
            if (res.status === 200 || res.status === 201) {
                dispatch(toastToggleAction({
                    show: true,
                    bg: 'success',
                    title: 'Exito!',
                    message: 'Creado correctamente!',
                    variant: 'bold'
                }))
                setCardState('show')
                handleCloseConfirmation()
                setButtonDisabled(false)
                dispatch(noteAssignedAction(res.data.id))
                setRecall(!recall)
            }
            else {
                dispatch(toastToggleAction({
                    show: true,
                    bg: 'warning',
                    title: 'Alerta!',
                    message: 'Los datos podrian no haberse cargado!',
                    variant: 'bold'
                }))
                setButtonDisabled(false)
            }
        } catch (error) {
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: 'Error al crear cuarderno diario!',
                variant: 'bold'
            }))
            setButtonDisabled(false)
        }
    }

    useEffect(() => {
        const getClientLocations = async () => {
            try {
                let res = await GetClientLocationsApi()
                if (res.status === 200) {
                    setClients(res.data)
                }
            } catch (error) {
                console.log("error al listar las localizaciones de los clientes")
            }
        }
        // if (user.departament.nombre === "Administración") {
        // if (window.sessionStorage.getItem('dp') === "adm") {
        getClientLocations()
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className="content">
                <div className="container-fluid mt-2 p-1">
                    {
                        user?.rols?.find(r => r.nombre === 'administrador') ?
                            <>
                                <CardAdmin
                                    note={currentNote}
                                    cardState={cardState}
                                    handleShowConfirmation={handleShowConfirmation}
                                />
                            </>
                            :
                            <>
                                <CardSeller
                                    note={currentNote}
                                    cardState={cardState}
                                    handleShowConfirmation={handleShowConfirmation}
                                />
                            </>
                    }
                    {
                        // (window.localStorage.getItem('dp') === 'vnt' || window.localStorage.getItem('dp') === 'pvnt') &&
                        // (window.sessionStorage.getItem('dp') === 'vnt' || window.sessionStorage.getItem('dp') === 'pvnt') &&
                        // (!user?.rols?.find(r => r.nombre === 'administrador') && (user?.rols?.find(r => r.nombre === 'ventas') || user?.rols?.find(r => r.nombre === 'punto-de-ventas'))) &&
                        // <>
                        //     <CardSeller
                        //         note={currentNote}
                        //         cardState={cardState}
                        //         handleShowConfirmation={handleShowConfirmation}
                        //     />

                        // </>
                    }
                    {
                        // window.localStorage.getItem('dp') === 'cnt' &&
                        window.sessionStorage.getItem('dp') === 'cnt' &&
                        <CardClient />
                    }
                </div>
            </section>
            {
                modalConfirmation &&
                <ModalConfirmation
                    show={modalConfirmation}
                    handleClose={handleCloseConfirmation}
                    text='¿Seguro que desea aperturar un cuaderno diario?'
                    buttonText='Sí, aperturar!'
                    variant='danger'
                    buttonDisabled={buttonDisabled}
                    onClick={() => createNote()}
                />
            }
        </>

    )
}
