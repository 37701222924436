import React, { useState } from 'react'

export default function ButtonSubmitSave({ text, disabled, color = '#dc3545' }) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <button
            type="submit"
            className="btn"
            // style={{ background: '#00bfa5', color: 'white' }}
            style={{
                background: disabled ? '#84d8cd' : isHovered ? '#b9303d' : color,
                color: 'rgb(250,250,250)',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            disabled={disabled}
        >
            {text}
        </button>
    )
}
