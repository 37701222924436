import React from 'react'
import { Link } from 'react-router-dom'

export default function CardBasic({ h3, description, icon, to, color, sup }) {
    return (
        <div className={"small-box " + color}>
            <div className="inner">
                <h3 className='animation__shake'>{h3}<sup style={{ fontSize: 20 }}> {sup}</sup></h3>
                <p>{description}</p>
            </div>
            <div className="icon">
                {icon}
            </div>
            {
                to &&
                <Link to={to} className="small-box-footer" style={{ height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}><span>Ver más</span> <i className="fas fa-arrow-circle-right" /></Link>
            }
        </div>
    )
}
