import React, { useEffect, useState } from 'react'
import { RequestAuthPaginated } from '../../../http/httpRequest'
import { DefaultTable } from '../../../components/table/DefaultTable'
import { useDispatch, useSelector } from 'react-redux'
import { ClienteTabGeneralButtons } from './ClienteTabGeneralButtons'
import { useParams } from 'react-router-dom'

export const AlmacenTab = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const globalParams = useSelector(state => state.globalParams)
    const [dataClientes, setDataClientes] = useState({
        data: [],
        recall: true,
        nav: true,
        navParam: '',
        navRef: 'id',
        loading: false,
        subId: 'clientId',
        params: {
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            pageSize: 25,
            // nombres: ''
            filterBy: 'nombres',
            filterParam: ''
        },
        filters: ['nombres', 'codigo'],
        selected: null,
        modalCrearCliente: false,
        modalEditarCliente: false,
        modalEliminarCliente: false
    })

    const getClientes = async () => {
        await RequestAuthPaginated(
            'get',
            `/almacen_producto/${params.almProdId}/historials/pag`,
            setDataClientes,
            'data',
            dispatch,
            dataClientes.params
        )
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataClientes.recall, dataClientes?.params?.pageSize, dataClientes?.params?.currentPage, dataClientes.params?.nombres, dataClientes.params?.filterParam, dataClientes.params?.filterBy, globalParams.recall1, params?.almProdId])

    return (
        <div>
            <ClienteTabGeneralButtons
                setDataClientes={setDataClientes}
                modals={{ modalCrearCliente: dataClientes.modalCrearCliente }}
            />
            <DefaultTable
                data={dataClientes}
                setData={setDataClientes}
                headers={[
                    {
                        label: 'Alm. Orig./Dest.',
                        value: ['nombreAlmDestino'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Transportista',
                        value: ['transporNombre'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Encargado',
                        value: ['encarNombre'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Ingr./Egre.',
                        value: ['ingreso'],
                        type: 'tag-bool',
                        multiLine: true,
                        refBool: ['Ingreso', 'Egreso'],
                        lineBold: [0],
                    },
                    {
                        label: 'Cantidad',
                        value: ['cantidad'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                        alignRight: true,
                    },
                    {
                        label: 'Concepto',
                        value: ['tipo'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Fecha de registro',
                        value: ['createdAt'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Acciones',
                        type: 'acciones',
                        actions: [
                            {
                                adCNButton: 'warning',
                                adCNIcon: 'pen-to-square',
                                title: 'Editar',
                                func: (item) => setDataClientes(x => ({ ...x, modalEditarCliente: true })),
                                // disabledFunc: (item) => {
                                //     if (item.estado === 'inactivo')
                                //         return true
                                //     return false
                                // }
                            },
                            {
                                adCNButton: 'danger',
                                adCNIcon: 'trash',
                                title: 'Borrar',
                                func: () => {
                                    setDataClientes(x => ({ ...x, modalEliminarCliente: true }))
                                },
                            },
                        ]
                    }
                ]}
            />
        </div>
    )
}
