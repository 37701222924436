import { ToTwoDecimal } from "../../../utils/utilities";

export const VenderProductoRetornableForm = (prevData, clientType) => {
    let tipo = clientType === 'cliente-mayor' ? 'venta-retornable-oficina' : 'venta-retornable'
    return (
        {
            initialValues: {
                cuaderno_id: prevData.id ?? '',
                almacen_producto_id: '',
                cantidad_total: 0,
                cantidad_total_restante: 0,
                departamento_id: 2,
                user_id: '',
                precio_unitario: 0,
                cantidad: 0,
                cantidad_retorno: 0,
                cantidad_deuda: 0,
                metodo_intercambio: 'normal',
                metodo_pago: 'efectivo',
                monto_garantia: 0,
                fecha_estimada_devolucion: '',
                venta_total: 0,
                efectivo_total: 0,
                deuda_total: 0,
                transferencia_total: 0,
                fecha_estimada_cobro: '',
                metodo_pago_garantia: 'efectivo',
                tipo: tipo,
                obs: '',
            },
            validators: {},
            fields: [
                {
                    label: 'Seleccione producto de almacén',
                    name: 'almacen_producto_id',
                    type: 'selectSearchDetailCard',
                    // param: 'user_id',
                    urlApi: `/user/${prevData.user_id}/almacenes/retornable`,
                    required: true,
                    labelDescription: 'etiqueta',
                    placeholder: 'Producto...',
                    autoFocus: true,
                    nameNumber: 'cantidadAlmacen',
                    labelNumberRestante: 'Restante',
                    nameNumberRestante: 'cantidad_total_restante',
                    alterField: 'cantidad',
                    exclamationDefault: 'Seleccione un producto!',
                    exclamationRestante: 'Stock insuficiente!',
                    infoTags: [
                        {
                            label: 'Producto',
                            data: 'producto_nombre'
                        },
                        {
                            label: 'Tipo',
                            data: 'tipoProducto'
                        },
                        {
                            label: 'En almacén',
                            data: 'cantidadAlmacen',
                            mark: true
                        },
                    ]
                },
                {
                    label: 'Cliente',
                    name: 'user_id',
                    type: 'selectApi',
                    urlApi: `/vendedor/${prevData.user_id}/clientes?tag=${clientType}`,
                    required: true,
                    indexValue: 'id',
                    indexLabel: 'etiqueta',
                    placeholder: 'Consumidor...',
                    autoFocus: false,
                    // defaultValue: { value: '2', label: 'Clientes' }
                },
                {
                    label: 'Precio unitario',
                    name: 'precio_unitario',
                    type: 'groupnumberCalculator',
                    param: 'Bs.',
                    required: true,
                    ChangeCalculator: (values, setValues, e) => {
                        const precio_unitario = e.target.valueAsNumber || 0;
                        const cantidad = values.cantidad || 0;
                        console.log(precio_unitario)
                        console.log(cantidad)
                        const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                        setValues('venta_total', venta_total)

                        const efectivo_total = values.efectivo_total || 0;
                        const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                        setValues('deuda_total', deuda_total)
                    }
                },
                {
                    label: 'Cantidad',
                    name: 'cantidad',
                    type: 'numberCalculator',
                    placeholder: '0',
                    required: false,
                    ChangeCalculator: (values, setFieldValue, e) => {
                        // console.log(values)
                        const cantidad = e.target.valueAsNumber || 0;
                        const precio_unitario = values.precio_unitario || 0;
                        const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                        setFieldValue('venta_total', venta_total)

                        const efectivo_total = values.efectivo_total || 0;
                        const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                        setFieldValue('deuda_total', deuda_total)

                        const cantidad_retorno = values.cantidad_retorno || 0
                        const cantidad_deuda = cantidad - cantidad_retorno
                        setFieldValue('cantidad_deuda', cantidad_deuda)

                        const cantidadAlmacen = values.cantidadAlmacen || 0
                        const total = ToTwoDecimal(cantidadAlmacen - cantidad)
                        setFieldValue('cantidad_total_restante', total < 0 ? 0 : total)
                    }
                },
                {
                    label: 'Cantidad vacios',
                    name: 'cantidad_retorno',
                    type: 'numberCalculator',
                    placeholder: '0',
                    required: false,
                    ChangeCalculator: (values, setFieldValue, e) => {
                        const cantidad_retorno = e.target.valueAsNumber || 0;
                        const cantidad = values.cantidad || 0
                        const cantidad_deuda = cantidad - cantidad_retorno
                        setFieldValue('cantidad_deuda', cantidad_deuda)
                    },
                },
                {
                    label: 'Cantidad deuda',
                    name: 'cantidad_deuda',
                    type: 'number',
                    placeholder: '0',
                    required: false,
                    disabled: true,
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        const cantidad_retorno = values.cantidad_retorno || 0
                        const cantidad = values.cantidad || 0
                        const cantidad_deuda = cantidad - cantidad_retorno
                        setFieldValue('cantidad_deuda', cantidad_deuda)
                    },
                    fieldDependency: 'cantidad',
                },
                {
                    label: 'Normal / Garantía / Prestamo',
                    name: 'metodo_intercambio',
                    type: 'selectCondition',
                    required: false,
                    options: [
                        {
                            label: 'Normal',
                            value: 'normal',
                            disabled: (values) => {
                                if (values?.cantidad_deuda > 0)
                                    return true
                            }
                        },
                        {
                            label: 'Garantía',
                            value: 'garantía',
                            disabled: (values) => {
                                if (values?.cantidad_deuda <= 0)
                                    return true
                            }
                        },
                        {
                            label: 'Prestamo',
                            value: 'préstamo',
                            disabled: (values) => {
                                if (values?.cantidad_deuda <= 0)
                                    return true
                            }
                        },
                    ],
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.cantidad_deuda > 0 && values.metodo_intercambio === 'normal')
                            setFieldValue('metodo_intercambio', 'garantía')
                        if (values.cantidad_deuda <= 0 && values.metodo_intercambio !== 'normal')
                            setFieldValue('metodo_intercambio', 'normal')
                    },
                    fieldDependency: 'cantidad_deuda',
                    default: true
                },
                {
                    label: 'Método de pago de garantía',
                    name: 'metodo_pago_garantia',
                    type: 'select',
                    required: true,
                    // disabled: disabled != null ? disabled : false,
                    options: [
                        {
                            label: 'Efectivo',
                            value: 'efectivo'
                        },
                        {
                            label: 'Transferencia',
                            value: 'transferencia'
                        },
                        {
                            label: 'Cheque',
                            value: 'cheque'
                        }
                    ],
                    ChangeCondition: (values, setValues, setAditionalClass) => {
                        if (values.metodo_intercambio === 'garantía')
                            setAditionalClass('')
                        else
                            setAditionalClass('hidden')
                    },
                    fieldDependency: 'metodo_intercambio',
                    highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Monto garantía',
                    name: 'monto_garantia',
                    type: 'groupnumber',
                    param: 'Bs.',
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.metodo_intercambio === 'garantía')
                            setAditionalClass('')
                        else
                            setAditionalClass('d-none')
                    },
                    fieldDependency: 'metodo_intercambio',
                    highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Fecha estimada de devolución',
                    name: 'fecha_estimada_devolucion',
                    type: 'date',
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.metodo_intercambio === 'préstamo')
                            setAditionalClass('')
                        else
                            setAditionalClass('d-none')
                    },
                    fieldDependency: 'metodo_intercambio',
                    highlight: 'bg-cyan-600 dark:bg-cyan-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    type: 'divider',
                },
                {
                    label: 'Método de pago',
                    name: 'metodo_pago',
                    type: 'select',
                    required: false,
                    options: [
                        {
                            label: 'Efectivo',
                            value: 'efectivo'
                        },
                        {
                            label: 'Transferencia',
                            value: 'transferencia'
                        },
                        {
                            label: 'Cheque',
                            value: 'cheque'
                        },
                        {
                            label: 'Combinado E/T',
                            value: 'combinado'
                        },
                    ],
                    default: true
                },
                {
                    label: 'Total venta',
                    name: 'venta_total',
                    type: 'groupnumber',
                    param: 'Bs.',
                    required: true,
                    disabled: true,
                    highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Total cobrado',
                    name: 'efectivo_total',
                    type: 'groupnumberCalculator',
                    param: 'Bs.',
                    ChangeCalculator: (values, setFieldValue, e) => {
                        const efectivo_total = e.target.valueAsNumber || 0;
                        const venta_total = values.venta_total || 0
                        const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                        setFieldValue('deuda_total', deuda_total)
                    },
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.metodo_pago !== 'efectivo') {
                            setAditionalClass('d-none')
                        }
                        else {
                            setAditionalClass('')
                        }
                    },
                    fieldDependency: 'metodo_pago',
                },
                {
                    label: 'Saldo',
                    name: 'deuda_total',
                    type: 'groupnumber',
                    param: 'Bs.',
                    required: true,
                    disabled: true,
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.metodo_pago !== 'efectivo') {
                            setAditionalClass('d-none')
                        }
                        else {
                            setAditionalClass('')
                        }
                    },
                    fieldDependency: 'metodo_pago',
                    highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Pago confirmar',
                    name: 'transferencia_total',
                    type: 'groupnumber',
                    param: 'Bs.',
                    required: false,
                    disabled: true,
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.metodo_pago === 'efectivo') {
                            setAditionalClass('d-none')
                            setFieldValue('transferencia_total', 0)
                        }
                        else {
                            setAditionalClass('')
                            setFieldValue('transferencia_total', values.venta_total)
                        }
                    },
                    fieldDependency: 'metodo_pago',
                    highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Fecha estimada de cobro',
                    name: 'fecha_estimada_cobro',
                    type: 'date',
                    required: false,
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.deuda_total > 0 && values.metodo_pago === 'efectivo')
                            setAditionalClass('')
                        else
                            setAditionalClass('d-none')
                    },
                    fieldDependency: 'deuda_total',
                    fieldDependencyExtra: 'metodo_pago',
                    highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Observaciones',
                    name: 'obs',
                    type: 'textArea',
                    placeholder: 'Obs...',
                    required: false,
                    rows: '2'
                },
                {
                    type: 'scheme',
                    data: (values) => {
                        return (
                            <>
                                <div className='table-primary p-1 row'>
                                    <div className='col-5 text-right'>
                                        Total a pagar:
                                    </div>
                                    <div className='col-4 text-right fw-bold text-primary'>
                                        {values?.venta_total}
                                    </div>
                                    <div className='col-3'>
                                        Bs.
                                    </div>
                                </div>
                                {
                                    values?.metodo_pago === 'efectivo' ?
                                        <>
                                            <div className='table-success p-1 row'>
                                                <div className='col-5 text-right'>
                                                    Total cobrado:
                                                </div>
                                                <div className='col-4 text-right fw-bold text-success'>
                                                    {values?.efectivo_total}
                                                </div>
                                                <div className='col-3'>
                                                    Bs.
                                                </div>
                                            </div>
                                            <div className='table-danger p-1 row'>
                                                <div className='col-5 text-right'>
                                                    Total deuda:
                                                </div>
                                                <div className='col-4 text-right fw-bold text-danger'>
                                                    {values?.deuda_total}
                                                </div>
                                                <div className='col-3'>
                                                    Bs.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className='table-danger p-1 row'>
                                            <div className='col-5 text-right'>
                                                Total transferencia/cheque:
                                            </div>
                                            <div className='col-4 text-right fw-bold text-danger'>
                                                {values?.transferencia_total}
                                            </div>
                                            <div className='col-3'>
                                                Bs.
                                            </div>
                                        </div>

                                }
                                <div className='table-warning p-1 row'>
                                    <div className='col-5 text-right'>
                                        Turriles a cuenta:
                                    </div>
                                    <div className='col-4 text-right fw-bold'>
                                        {values?.cantidad_deuda}
                                    </div>
                                    <div className='col-3'>
                                        {values?.metodo_intercambio}
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
            ],
        }
    )
}

export const VenderProductoNoRetornableForm = (prevData, clientType) => {
    let tipo = clientType === 'cliente-mayor' ? 'venta-no-retornable-oficina' : 'venta-no-retornable'
    return (
        {
            initialValues: {
                cuaderno_id: prevData.id,
                almacen_producto_id: '',
                // departamento_id: '1',
                user_id: '',
                precio_unitario: 0,
                cantidad: 0,
                metodo_intercambio: 'normal',
                metodo_pago: 'efectivo',
                venta_total: 0,
                efectivo_total: 0,
                deuda_total: 0,
                transferencia_total: 0,
                fecha_estimada_cobro: '',
                tipo: tipo,
                obs: '',
            },
            validators: {},
            fields: [
                {
                    label: 'Seleccione producto de almacén',
                    name: 'almacen_producto_id',
                    type: 'selectSearchDetailCard',
                    // param: 'user_id',
                    urlApi: `/user/${prevData.user_id}/almacenes/no-retornable`,
                    required: true,
                    labelDescription: 'etiqueta',
                    placeholder: 'Producto...',
                    autoFocus: true,
                    nameNumber: 'cantidadAlmacen',
                    labelNumberRestante: 'Restante',
                    nameNumberRestante: 'cantidad_total_restante',
                    alterField: 'cantidad',
                    exclamationDefault: 'Seleccione un producto!',
                    exclamationRestante: 'Stock insuficiente!',
                    infoTags: [
                        {
                            label: 'Producto',
                            data: 'producto_nombre'
                        },
                        {
                            label: 'En almacén',
                            data: 'cantidadAlmacen',
                            mark: true
                        },
                    ]
                },
                {
                    label: 'Cliente',
                    name: 'user_id',
                    type: 'selectApi',
                    urlApi: `/vendedor/${prevData.user_id}/clientes?tag=${clientType}`,
                    required: true,
                    indexValue: 'id',
                    indexLabel: 'etiqueta',
                    placeholder: 'Consumidor...',
                    autoFocus: false,
                    // defaultValue: { value: '2', label: 'Clientes' }
                },
                {
                    label: 'Precio unitario',
                    name: 'precio_unitario',
                    type: 'groupnumber',
                    param: 'Bs.',
                    required: true,
                    ChangeCalculator: (values, setValues, e) => {
                        const precio_unitario = e.target.valueAsNumber || 0;
                        const cantidad = values.cantidad || 0;
                        const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                        setValues('venta_total', venta_total)

                        const efectivo_total = values.efectivo_total || 0;
                        const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                        setValues('deuda_total', deuda_total)
                    }
                },
                {
                    label: 'Cantidad',
                    name: 'cantidad',
                    type: 'numberCalculator',
                    placeholder: '0',
                    required: false,
                    ChangeCalculator: (values, setFieldValue, e) => {
                        const cantidad = e.target.valueAsNumber || 0;
                        const precio_unitario = values.precio_unitario || 0;
                        const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                        setFieldValue('venta_total', venta_total)

                        const efectivo_total = values.efectivo_total || 0;
                        const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                        setFieldValue('deuda_total', deuda_total)

                        const cantidad_retorno = values.cantidad_retorno || 0
                        const cantidad_deuda = cantidad - cantidad_retorno
                        setFieldValue('cantidad_deuda', cantidad_deuda)

                        const cantidadAlmacen = values.cantidadAlmacen || 0
                        const total = ToTwoDecimal(cantidadAlmacen - cantidad)
                        setFieldValue('cantidad_total_restante', total < 0 ? 0 : total)
                    }
                },
                // {
                //     label: 'Cantidad vacios',
                //     name: 'cantidad_retorno',
                //     type: 'numberCalculator',
                //     placeholder: '0',
                //     required: false,
                //     ChangeCalculator: (values, setFieldValue, e) => {
                //         const cantidad_retorno = e.target.valueAsNumber || 0;
                //         const cantidad = values.cantidad || 0
                //         const cantidad_deuda = cantidad - cantidad_retorno
                //         setFieldValue('cantidad_deuda', cantidad_deuda)
                //     },
                // },
                // {
                //     label: 'Cantidad deuda',
                //     name: 'cantidad_deuda',
                //     type: 'number',
                //     placeholder: '0',
                //     required: false,
                //     disabled: true,
                //     ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                //         const cantidad_retorno = values.cantidad_retorno || 0
                //         const cantidad = values.cantidad || 0
                //         const cantidad_deuda = cantidad - cantidad_retorno
                //         setFieldValue('cantidad_deuda', cantidad_deuda)
                //     },
                //     fieldDependency: 'cantidad',
                // },
                // {
                //     label: 'Normal / Garantía / Prestamo',
                //     name: 'metodo_intercambio',
                //     type: 'selectCondition',
                //     required: false,
                //     options: [
                //         {
                //             label: 'Normal',
                //             value: 'normal',
                //             disabled: (values) => {
                //                 if (values?.cantidad_deuda > 0)
                //                     return true
                //             }
                //         },
                //         {
                //             label: 'Garantía',
                //             value: 'garantía',
                //             disabled: (values) => {
                //                 if (values?.cantidad_deuda <= 0)
                //                     return true
                //             }
                //         },
                //         {
                //             label: 'Prestamo',
                //             value: 'préstamo',
                //             disabled: (values) => {
                //                 if (values?.cantidad_deuda <= 0)
                //                     return true
                //             }
                //         },
                //     ],
                //     ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                //         if (values.cantidad_deuda > 0 && values.metodo_intercambio === 'normal')
                //             setFieldValue('metodo_intercambio', 'garantía')
                //         if (values.cantidad_deuda <= 0 && values.metodo_intercambio !== 'normal')
                //             setFieldValue('metodo_intercambio', 'normal')
                //     },
                //     fieldDependency: 'cantidad_deuda',
                //     default: true
                // },
                // {
                //     label: 'Monto garantía',
                //     name: 'monto_garantia',
                //     type: 'groupnumber',
                //     param: 'Bs.',
                //     ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                //         if (values.metodo_intercambio === 'garantía')
                //             setAditionalClass('')
                //         else
                //             setAditionalClass('d-none')
                //     },
                //     fieldDependency: 'metodo_intercambio',
                //     highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
                // },
                // {
                //     label: 'Fecha estimada de devolución',
                //     name: 'fecha_estimada_devolucion',
                //     type: 'date',
                //     ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                //         if (values.metodo_intercambio === 'préstamo')
                //             setAditionalClass('')
                //         else
                //             setAditionalClass('d-none')
                //     },
                //     fieldDependency: 'metodo_intercambio',
                //     highlight: 'bg-cyan-600 dark:bg-cyan-500 bg-opacity-25 dark:bg-opacity-20'
                // },
                {
                    type: 'divider',
                },
                {
                    label: 'Método de pago',
                    name: 'metodo_pago',
                    type: 'select',
                    required: false,
                    options: [
                        {
                            label: 'Efectivo',
                            value: 'efectivo'
                        },
                        {
                            label: 'Transferencia',
                            value: 'transferencia'
                        },
                        {
                            label: 'Cheque',
                            value: 'cheque'
                        },
                    ],
                    default: true
                },
                {
                    label: 'Total venta',
                    name: 'venta_total',
                    type: 'groupnumber',
                    param: 'Bs.',
                    required: true,
                    disabled: true,
                    highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Total cobrado',
                    name: 'efectivo_total',
                    type: 'groupnumberCalculator',
                    param: 'Bs.',
                    ChangeCalculator: (values, setFieldValue, e) => {
                        const efectivo_total = e.target.valueAsNumber || 0;
                        const venta_total = values.venta_total || 0
                        const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                        setFieldValue('deuda_total', deuda_total)
                    },
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.metodo_pago !== 'efectivo') {
                            setAditionalClass('d-none')
                        }
                        else {
                            setAditionalClass('')
                        }
                    },
                    fieldDependency: 'metodo_pago',
                },
                {
                    label: 'Saldo',
                    name: 'deuda_total',
                    type: 'groupnumber',
                    param: 'Bs.',
                    required: true,
                    disabled: true,
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.metodo_pago !== 'efectivo') {
                            setAditionalClass('d-none')
                        }
                        else {
                            setAditionalClass('')
                        }
                    },
                    fieldDependency: 'metodo_pago',
                    highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Pago confirmar',
                    name: 'transferencia_total',
                    type: 'groupnumber',
                    param: 'Bs.',
                    required: false,
                    disabled: true,
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.metodo_pago === 'efectivo') {
                            setAditionalClass('d-none')
                            setFieldValue('transferencia_total', 0)
                        }
                        else {
                            setAditionalClass('')
                            setFieldValue('transferencia_total', values.venta_total)
                        }
                    },
                    fieldDependency: 'metodo_pago',
                    highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Fecha estimada de cobro',
                    name: 'fecha_estimada_cobro',
                    type: 'date',
                    required: false,
                    ChangeCondition: (values, setFieldValue, setAditionalClass) => {
                        if (values.deuda_total > 0 && values.metodo_pago === 'efectivo')
                            setAditionalClass('')
                        else
                            setAditionalClass('d-none')
                    },
                    fieldDependency: 'deuda_total',
                    fieldDependencyExtra: 'metodo_pago',
                    highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
                },
                {
                    label: 'Observaciones',
                    name: 'obs',
                    type: 'textArea',
                    placeholder: 'Obs...',
                    required: false,
                    rows: '2'
                },
                {
                    type: 'scheme',
                    data: (values) => {
                        return (
                            <>
                                <div className='table-primary p-1 row'>
                                    <div className='col-5 text-right'>
                                        Total a pagar:
                                    </div>
                                    <div className='col-4 text-right fw-bold text-primary'>
                                        {values?.venta_total}
                                    </div>
                                    <div className='col-3'>
                                        Bs.
                                    </div>
                                </div>
                                {
                                    values?.metodo_pago === 'efectivo' ?
                                        <>
                                            <div className='table-success p-1 row'>
                                                <div className='col-5 text-right'>
                                                    Total cobrado:
                                                </div>
                                                <div className='col-4 text-right fw-bold text-success'>
                                                    {values?.efectivo_total}
                                                </div>
                                                <div className='col-3'>
                                                    Bs.
                                                </div>
                                            </div>
                                            <div className='table-danger p-1 row'>
                                                <div className='col-5 text-right'>
                                                    Total deuda:
                                                </div>
                                                <div className='col-4 text-right fw-bold text-danger'>
                                                    {values?.deuda_total}
                                                </div>
                                                <div className='col-3'>
                                                    Bs.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className='table-danger p-1 row'>
                                            <div className='col-5 text-right'>
                                                Total transferencia/cheque:
                                            </div>
                                            <div className='col-4 text-right fw-bold text-danger'>
                                                {values?.transferencia_total}
                                            </div>
                                            <div className='col-3'>
                                                Bs.
                                            </div>
                                        </div>

                                }
                            </>
                        )
                    }
                },
            ],
        }
    )
}

export const CobrarDeudaForm = (prevData) => {
    return {
        initialValues: {
            cuaderno_id: prevData?.id,
            deuda_id: '',
            metodo_pago: 'efectivo',
            saldo: 0,
            saldo_restante: 0,
            monto: 0,
            obs: '',
        },
        fieldsValidation: {},
        fields: [
            {
                label: 'Seleccion un usuario',
                name: 'deuda_id',
                type: 'selectSearchDetailCard',
                urlApi: `/user/${prevData?.user_id}/deudas`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: 'El resto debe ser mayor o igual a 0!',
                setAction: (setValues, data) => {
                    if (data != null) {
                        setValues('monto_garantia', data.monto_garantia)
                        setValues('monto_restante', data.monto_garantia)
                    }
                    else {
                        setValues('monto_garantia', 0)
                        setValues('monto_restante', 0)
                        setValues('monto', 0)
                        setValues('cantidad', 0)
                        setValues('cantidad_restante', 0)
                    }
                },
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'deudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    // {
                    //     label: 'Transferencia',
                    //     value: 'transferencia'
                    // },
                    // {
                    //     label: 'Cheque',
                    //     value: 'cheque'
                    // },
                ],
            },
            {
                label: 'Saldo',
                name: 'saldo',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const saldo = e.target.valueAsNumber || 0;
                    const monto = values.saldo || 0
                    const monto_restante = saldo - monto
                    setValues('saldo_restante', monto_restante)
                },
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto devuelto',
                name: 'monto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0
                    const monto_restante = saldo - monto
                    setValues('saldo_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Saldo restante',
                name: 'saldo_restante',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('saldo_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
                fieldDependencyExtra: 'monto',
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const GastoForm = (prevData) => {
    return {
        initialValues: {
            cuaderno_id: prevData?.id,
            estado_gasto_id: '',
            monto: 0,
            obs: '',

        },
        validators: {},
        fields: [
            {
                label: 'Tipo de gasto',
                name: 'estado_gasto_id',
                type: 'selectApi',
                urlApi: '/nombreEstado/Gastos vendedores/subEstados',
                required: true,
                indexValue: 'id',
                indexLabel: 'nombre',
                // labelDescription: 'nombre',
                placeholder: 'Tipo de gasto...',
                autoFocus: false,
                // defaultValue: { value: '1', label: 'Clientes' }
            },

            {
                label: 'Monto del Gasto',
                name: 'monto',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,

            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DevolucionDePrestamosForm = (prevData) => {
    return {
        initialValues: {
            cuaderno_id: prevData?.id,
            deuda_envase_id: '',
            saldo: 0,
            saldo_restante: 0,
            cantidad: 0,
            obs: '',
        },
        fieldsValidation: {},
        fields: [
            {
                label: 'Seleccion un usuario',
                name: 'deuda_envase_id',
                type: 'selectSearchDetailCard',
                // param: 'user_id',
                urlApi: `/user/${prevData?.user_id}/prestamos`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: 'El resto debe ser mayor o igual a 0!',
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'envaseDeudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Fecha límite',
                        data: 'fecha_limite',
                        isDateValue: true
                    },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Envases devueltos',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                }
            },
            {
                label: 'Envases restantes',
                name: 'saldo_restante',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: true,
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DevolucionDeGarantiasForm = (prevData) => {
    return {
        initialValues: {
            cuaderno_id: prevData?.id,
            deuda_envase_id: '',
            metodo_pago: 'efectivo',
            saldo: 0,
            saldo_restante: 0,
            cantidad: 0,
            monto: 0,
            monto_garantia: 0,
            monto_restante: 0,
            obs: '',
        },
        fieldsValidation: {},
        fields: [
            {
                label: 'Seleccion un usuario',
                name: 'deuda_envase_id',
                type: 'selectSearchDetailCard',
                urlApi: `/user/${prevData?.user_id}/garantias`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: 'El resto debe ser mayor o igual a 0!',
                setAction: (setValues, data) => {
                    if (data != null) {
                        setValues('monto_garantia', data.monto_garantia)
                        setValues('monto_restante', data.monto_garantia)
                    }
                    else {
                        setValues('monto_garantia', 0)
                        setValues('monto_restante', 0)
                        setValues('monto', 0)
                        setValues('cantidad', 0)
                        setValues('cantidad_restante', 0)
                    }
                },
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'envaseDeudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Monto Garantía Bs.',
                        data: 'monto_garantia',
                        mark: true,
                    },
                    // {
                    //     label: 'Fecha límite:',
                    //     data: 'fecha_limite',
                    //     isDateValue: true
                    // },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    // {
                    //     label: 'Transferencia',
                    //     value: 'transferencia'
                    // },
                    // {
                    //     label: 'Cheque',
                    //     value: 'cheque'
                    // },
                ],
            },
            {
                label: 'Garantía',
                name: 'monto_garantia',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto_garantia = e.target.valueAsNumber || 0;
                    const monto = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto devuelto',
                name: 'monto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Garantía restante',
                name: 'monto_restante',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
                fieldDependencyExtra: 'monto_garantia',
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Envases recogidos',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Envases restantes',
                name: 'saldo_restante',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: true,
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}
