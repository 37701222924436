import axios from "axios";

// export async function GetMyNotesApi(page, perPage, forQuery, typeQuery, orderBy, orderByDesc = "DESC") {
export async function GetMyNotesApi() {
    const response = axios({
        url: `/note`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function CreateNoteApi() {
    const response = axios({
        url: `/openCuaderno`,
        method: 'post',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
            // data: { user_id }
        }
    });
    return response
}

export async function DeleteNoteApi(id) {
    const response = axios({
        url: `/note/${id}`,
        method: 'delete',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetHistoryNotesByNoteIdApi(id, page, perPage, forQuery, typeQuery, orderBy, orderByDesc = "DESC") {
    const response = axios({
        url: `/note/${id}/sales?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetPaymentsApi(id, page, perPage, forQuery, typeQuery, orderBy, orderByDesc = "DESC") {
    const response = axios({
        url: `/note/${id}/payments?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetExpenseNotesApi(id, page, perPage, forQuery, typeQuery, orderBy, orderByDesc = "DESC") {
    const response = axios({
        url: `/note/${id}/expenses?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetBarrelAccountNotesApi(id, page, perPage, forQuery, typeQuery, orderBy, orderByDesc = "DESC") {
    const response = axios({
        url: `/note/${id}/barrelAccounts?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetMyCurrentNoteApi() {
    const response = axios({
        url: `/cuadernoAbierto`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function VerificationCurrentNoteApi(id) {
    const response = axios({
        url: `/note/${id}/verification`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetNoteByIdApi(id) {
    const response = axios({
        url: `/note/${id}/first`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetMyCurrentNoteIdApi() {
    const response = axios({
        url: `/note/current/id`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function UpdateCashNoteApi(data) {
    const response = axios({
        url: `/note/cash`,
        method: 'put',
        data: data,
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetNoteAllDataApi(id) {
    const response = axios({
        url: `note/${id}/fullData`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}