import React, { useEffect, useState } from 'react'
import GeneralContainer from '../../globalParams/GeneralContainer'
import { DefaultRequestAuth } from '../../../http/httpRequest'
import { useDispatch, useSelector } from 'react-redux'
// import { Routes } from 'react-router-dom'
// import { Route } from 'react-router-dom'
// import { ClienteTab } from './ClienteTab'
// import { ModalForm } from '../../../components/modal/ModalForm'
// import { EditarClienteForm, EliminarClienteForm } from './ClienteTabFormDatas'
import { SimpleTable } from '../../../components/table/SimpleTable'
import { MiCuadernoCard } from './MiCuadernoCard'
// import { useParams } from 'react-router-dom'
import { ModalForm } from '../../../components/modal/ModalForm'
import { VenderProductoRetornableForm } from './MiCuadernoFormDatas'
import Loading from '../../globalParams/Loading'
import CardButton from '../../../components/card/CardButton'

export const MiCuadernoBoard = () => {
    const dispatch = useDispatch();
    const globalParams = useSelector(state => state.globalParams)
    const [dataCuaderno, setDataCuaderno] = useState({
        data: {},
        recall: true,
        nav: false,
        navParam: '',
        navRef: 'id',
        loading: false,
        subId: 'clientId',
        params: null,
        filters: [],
        selected: null,
        modalCrearCliente: false,
        modalEditarCliente: false,
        modalEliminarCliente: false,
        modalOpenNote: false,
        hiddenInfo: true
    })
    // const params = useParams()
    const getClientes = async () => {
        await DefaultRequestAuth(
            'get',
            // `/cuadernos/${params.cuaderno_id}/fullData`,
            `/cuadernoAbierto`,
            setDataCuaderno,
            'data',
            dispatch,
            dataCuaderno.params
        )
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalParams.recall1])
    if (!dataCuaderno?.data.id && dataCuaderno.loading)
        return <div style={{ padding: '10px 15px' }}><Loading /></div>
    if (!dataCuaderno?.data.id)
        return <>
            <div style={{ padding: '10px 15px' }} className="col-12 col-lg-12">
                <CardButton
                    title='Aperturar cuaderno diario'
                    sup=''
                    description='Cuadernos diarios'
                    icon={<i className="ion ion-ios-bookmarks" />}
                    color='warning'
                    onClick={() => setDataCuaderno(x => ({ ...x, modalOpenNote: true }))}
                />
            </div>
            {
                dataCuaderno.modalOpenNote &&
                <>
                    <ModalForm
                        show={dataCuaderno.modalOpenNote}
                        onHide={() => setDataCuaderno(x => ({ ...x, modalOpenNote: false }))}
                        urlApi={'/openCuaderno'}
                        method='post'
                        title='¿Seguro que desea aperturar un cuaderno diario?'
                        formData={{
                            initialValues: {},
                            validators: {},
                            fields: []
                        }}
                    />
                </>
            }
        </>
    return (
        <>
            <GeneralContainer>
                <MiCuadernoCard
                    loading={dataCuaderno?.loading}
                    data={dataCuaderno?.data}
                />
                <SimpleTable
                    data={dataCuaderno?.data?.ventas}
                    setData={setDataCuaderno}
                    title='Ventas'
                    titleColor='danger'
                    loading={dataCuaderno?.loading}
                    headers={[
                        {
                            label: 'Cliente',
                            value: ['user_nombres', 'user_apellido_paterno'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Producto',
                            value: ['producto_nombre'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Cantidad',
                            value: ['cantidad'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                            alignRight: true
                        },
                        {
                            label: 'Recogidos',
                            value: ['cantidad_retorno'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                            alignRight: true
                        },
                        {
                            label: 'Cobrado',
                            value: ['efectivo_total'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                            alignRight: true
                        },
                        {
                            label: 'Deuda',
                            value: ['deuda_total'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                            alignRight: true
                        },
                        {
                            label: 'Acciones',
                            type: 'acciones',
                            actions: [
                                {
                                    adCNButton: 'danger',
                                    adCNIcon: 'eye',
                                    title: 'Editar',
                                    func: () => setDataCuaderno(x => ({ ...x, modalEditarCliente: true })),
                                    disabledFunc: (item) => {
                                        if (item.estado === 'inactivo')
                                            return true
                                        return false
                                    }
                                }
                            ]
                        }
                    ]}
                />
                <SimpleTable
                    data={dataCuaderno?.data?.cobros}
                    setData={setDataCuaderno}
                    title='Cobros'
                    titleColor='primary'
                    loading={dataCuaderno?.loading}
                    headers={[
                        {
                            label: 'Cliente',
                            value: ['deudorDetalles'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Producto',
                            value: ['producto_nombre'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Cobrado',
                            value: ['monto'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                            alignRight: true
                        },
                        {
                            label: 'Acciones',
                            type: 'acciones',
                            actions: [
                                {
                                    adCNButton: 'primary',
                                    adCNIcon: 'eye',
                                    title: 'Editar',
                                    func: () => setDataCuaderno(x => ({ ...x, modalEditarCliente: true })),
                                    disabledFunc: (item) => {
                                        if (item.estado === 'inactivo')
                                            return true
                                        return false
                                    }
                                }
                            ]
                        }
                    ]}
                />
                <SimpleTable
                    data={dataCuaderno?.data?.gastos}
                    setData={setDataCuaderno}
                    title='Gastos'
                    titleColor='warning'
                    loading={dataCuaderno?.loading}
                    headers={[
                        {
                            label: 'Monto',
                            value: ['monto'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                            alignRight: true
                        },
                        {
                            label: 'Tipo',
                            value: ['estado_gasto_nombre'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Descripción',
                            value: ['obs'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Acciones',
                            type: 'acciones',
                            actions: [
                                {
                                    adCNButton: 'warning',
                                    adCNIcon: 'eye',
                                    title: 'Editar',
                                    func: () => setDataCuaderno(x => ({ ...x, modalEditarCliente: true })),
                                    disabledFunc: (item) => {
                                        if (item.estado === 'inactivo')
                                            return true
                                        return false
                                    }
                                }
                            ]
                        }
                    ]}
                />
                <SimpleTable
                    data={dataCuaderno?.data?.envasesRecogidos}
                    setData={setDataCuaderno}
                    title='Turriles recogidos'
                    titleColor='success'
                    loading={dataCuaderno?.loading}
                    headers={[
                        {
                            label: 'Cliente',
                            value: ['envaseDeudorDetalles'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Tipo',
                            value: ['metodo_intercambio'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Producto',
                            value: ['producto_nombre'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                        },
                        {
                            label: 'Cantidad',
                            value: ['cantidad'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                            alignRight: true
                        },
                        {
                            label: 'Monto',
                            value: ['monto'],
                            type: 'default',
                            multiLine: true,
                            lineBold: [0],
                            alignRight: true
                        },
                        {
                            label: 'Acciones',
                            type: 'acciones',
                            actions: [
                                {
                                    adCNButton: 'success',
                                    adCNIcon: 'eye',
                                    title: 'Editar',
                                    func: () => setDataCuaderno(x => ({ ...x, modalEditarCliente: true })),
                                    disabledFunc: (item) => {
                                        if (item.estado === 'inactivo')
                                            return true
                                        return false
                                    }
                                }
                            ]
                        }
                    ]}
                />
                <ModalForm
                    show={dataCuaderno.modalVenderProductoRetornable}
                    onHide={() => setDataCuaderno(x => ({ ...x, modalVenderProductoRetornable: false }))}
                    urlApi={'/userCliente'}
                    method='put'
                    formData={VenderProductoRetornableForm(dataCuaderno.data)}
                />
            </GeneralContainer>
        </>
    )
}
