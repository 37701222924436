import React, { useEffect, useMemo, useRef, useState } from 'react'
import Label from '../label/Label'
import { Field } from 'formik'
import ReactSelect from 'react-select'
import { DefaultRequestAuth } from '../../http/httpRequest'
import { useDispatch } from 'react-redux'
import { Accordion, Form, InputGroup } from 'react-bootstrap'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import config from '../../config.json'
import { VenueLocationIcon } from '../maps/VenueIconLocation'
import { formatDefaultDate } from '../../utils/utilities'

export const ModalFormField = ({ formData = {}, values, setFieldValue, handleChange, errors, touched }) => {
    return (
        // <div className='container'>
        <div className='row'>
            {
                formData?.fields?.map((field, index) => (
                    <FieldParam
                        key={index}
                        field={field}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                    />
                ))
            }
        </div>
        // </div>
    )
}

const FieldParam = ({ field, values, setFieldValue, handleChange, errors, touched }) => {
    const [aditionalClass, setAditionalClass] = useState('');
    if (field.highlight === null || field.highlight === '' || field.highlight === undefined)
        field.highlight = 'bg-gray-50 dark:bg-gray-600'

    useEffect(() => {
        if (field.fieldDependency !== undefined && field.fieldDependency !== null) {
            field.ChangeCondition(values, setFieldValue, setAditionalClass)
        }
        if (field.fieldDependencyExtra !== null && field.fieldDependencyExtra !== undefined) {
            field.ChangeCondition(values, setFieldValue, setAditionalClass)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values[field.fieldDependency], values[field.fieldDependencyExtra]]);


    switch (field.type) {
        case 'text':
        case 'email':
        case 'password':
        case 'date':
        case 'datetime-local':
        case 'number':
            return (
                <div className={`${aditionalClass} col-md-6 mb-2`}>
                    <Label
                        title={field.label}
                    >
                        <Field
                            id={field.name}
                            placeholder={field.placeholder}
                            name={field.name}
                            type={field.type}
                            disabled={field.disabled}
                            className='form-control fw-bold text-primary'
                            style={{ fontSize: '14px' }}
                            required={field.disabled}
                            autoComplete='new-password'
                        />
                    </Label>
                </div>
            )
        case 'title':
            return (
                <div className={`${aditionalClass} col-12 my-2 p-1 text-center`}>
                    <small><strong>{field.label}</strong> <span>{field.subLabel}</span></small>
                </div>
            )
        case 'selectApi':
            return (
                <SelectApi
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    aditionalClass={aditionalClass}
                />
            )
        case 'select':
            return (
                <Select
                    field={field}
                    values={values}
                />
            )
        case 'selectCondition':
            return (
                <SelectCondition
                    field={field}
                    values={values}
                />
            )
        case 'geo':
            return (
                <Geo
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    aditionalClass={aditionalClass}
                />
            )
        case 'checkboxes':
            return (
                <Checkboxes
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    aditionalClass={aditionalClass}
                />
            )
        case 'textArea':
            return (
                <div className={`${aditionalClass} col-12`}>
                    <Label
                        title='Observaciones'
                    >
                        <Form.Control
                            rows={field.rows}
                            name={field.name}
                            id={field.name}
                            className='rounded-lg border border-gray-300 fw-bold text-sm'
                            placeholder={field.placeholder}
                            required={field.required}
                            autoFocus={field.autoFocus}
                            value={values[field?.name]}
                            onChange={handleChange}
                            as="textarea"
                        />
                    </Label>
                </div>
            )
        case 'selectSearchDetailCard':
            return (
                <SelectSearchDetailCard
                    field={field}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                />
            )
        case 'groupnumber':
            return (
                <div className={`${aditionalClass} col-md-6 mb-2`}>
                    <Label
                        title={field.label}
                    >
                        <InputGroup>
                            {field.param &&
                                <InputGroup.Text><em><small>{field.param}</small></em></InputGroup.Text>
                            }
                            <Field
                                id={field.name}
                                placeholder={field.placeholder}
                                name={field.name}
                                type={field.type}
                                disabled={field.disabled}
                                className='form-control fw-bold text-primary'
                                style={{ fontSize: '14px' }}
                                required={field.disabled}
                                autoComplete='new-password'
                            />
                        </InputGroup>
                    </Label>
                </div>
            )
        case 'numberCalculator':
            return (
                <div className={`${aditionalClass} col-md-6 mb-2`}>
                    <Label
                        title={field.label}
                    >
                        <InputGroup>
                            <Field
                                id={field.name}
                                placeholder={field.placeholder}
                                name={field.name}
                                type='number'
                                disabled={field.disabled}
                                className='form-control fw-bold text-primary'
                                style={{ fontSize: '14px' }}
                                required={field.disabled}
                                autoComplete='new-password'
                                onChange={(e) => {
                                    handleChange(e)
                                    field.ChangeCalculator(values, setFieldValue, e)
                                }}
                            />
                        </InputGroup>
                    </Label>
                </div>
            )
        case 'groupnumberCalculator':
            return (
                <div className={`${aditionalClass} col-md-6 mb-2`}>
                    <Label
                        title={field.label}
                    >
                        <InputGroup>
                            {field.param &&
                                <InputGroup.Text><em><small>{field.param}</small></em></InputGroup.Text>
                            }
                            <Field
                                id={field.name}
                                placeholder={field.placeholder}
                                name={field.name}
                                type='number'
                                disabled={field.disabled}
                                className='form-control fw-bold text-primary'
                                style={{ fontSize: '14px' }}
                                required={field.disabled}
                                autoComplete='new-password'
                                onChange={(e) => {
                                    handleChange(e)
                                    field.ChangeCalculator(values, setFieldValue, e)
                                }}
                            />
                        </InputGroup>
                    </Label>
                </div>
            )
        case 'scheme':
            return (
                <div className={`${aditionalClass} mt-3 container text-sm col-md-8 p-4`}>
                    {field.data(values)}
                </div>
            )
        case 'divider':
            return (
                <div className={`${aditionalClass} col-12`}>
                </div>
            )
        default:
            break;
    }
}

const SelectApi = ({ field, values, setFieldValue }) => {
    const [data, setData] = useState({
        data: {},
        options: []
    })
    const dispatch = useDispatch()
    const getCliente = async () => {
        var res = await DefaultRequestAuth(
            'get',
            field.urlApi,
            setData,
            'data',
            dispatch,
        )

        res?.data?.map(i =>
            !data?.options.some(a => a.value === i.id) && data?.options?.push({ value: i[field.indexValue], label: i[field.indexLabel] })
        )
    }
    useEffect(() => {
        getCliente()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='col-sm-6 mb-2'>
            <Label
                title={field.label}
            >
                <Field name={field.name}>
                    {() => (

                        <ReactSelect
                            maxMenuHeight={200}
                            options={
                                data.options
                            }
                            // value={user}
                            onChange={(e) => {
                                setFieldValue(field.name, e?.value ?? '')
                                // setValues(field.name, e?.value ?? '')
                                // setExtraData(data.find(l => (l.id + '') === e?.value))
                            }}
                            isDisabled={field.disabled}
                            required={false}
                            isClearable={true}
                            className='text-primary fw-bold'
                        />
                    )}
                </Field>
            </Label>
        </div>
    )
}

const Select = ({ field }) => {

    return (
        <div className="col-md-6 mb-2 mb-2">
            <Label
                title={field.label}
            >
                <Field
                    id={field.name}
                    as='select'
                    name={field.name}
                    required={field.required}
                    className='form-select text-sm fw-bold'
                >
                    {
                        field.default &&
                        <option value=''>...</option>
                    }
                    {
                        field?.options?.map((option, index) => (
                            <option value={option?.value} key={index}>{option?.label}</option>
                        ))
                    }
                </Field>
            </Label>
        </div>
    )
}

const SelectCondition = ({ field, values }) => {

    return (
        <div className="col-md-6 mb-2 mb-2">
            <Label
                title={field.label}
            >
                <Field
                    id={field.name}
                    as='select'
                    name={field.name}
                    required={field.required}
                    className='form-select text-sm fw-bold'
                >
                    {
                        field.default &&
                        <option value=''>...</option>
                    }
                    {
                        field?.options?.map((option, index) => (
                            <option value={option?.value} key={index} disabled={option.disabled ? option.disabled(values) : false}>{option?.label}</option>
                        ))
                    }
                </Field>
            </Label>
        </div>
    )
}

const Geo = ({ field, values, setFieldValue }) => {
    var center = {
        lat: config.lat,
        lng: config.long,
    }
    if (values[field.name] !== null && values[field.name] !== '' && values[field.name].split(',').length > 1) {
        center.lat = values[field.name].split(',')[0]
        center.lng = values[field.name].split(',')[1]
    }
    const markerRef = useRef(null)
    const centerDefault = ''

    const [position, setPosition] = useState(center)
    const [location, setLocation] = useState(centerDefault)

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                    setLocation(marker._latlng.lat + ',' + marker._latlng.lng)
                    setFieldValue(field.name, (marker._latlng.lat + ',' + marker._latlng.lng))
                }
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )
    const currentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLocation(position.coords.latitude + "," + position.coords.longitude)
            setPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
        });
    }
    return (
        <>
            <div className="col-12">
                <div className="row">
                    <div className="col-9">
                        <Label
                            title='Geolocalización'
                        >
                            <Field
                                id={field.name}
                                placeholder={field.placeholder}
                                name={field.name}
                                type={field.type}
                                disabled={field.disabled}
                                className='form-control fw-semibold text-primary'
                                style={{ fontSize: '14px' }}
                                required={field.disabled}
                            />
                        </Label>
                    </div>
                    <div className="col-3 mt-4 text-right">
                        <span className="input-group-text btn btn-primary" type='button' onClick={() => currentLocation()}><i className='bi bi-geo-alt-fill' /></span>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className='p-0 text-dark' >Editar ubicación manualmente</Accordion.Header>
                        <Accordion.Body className='p-1 border border-secondary border-3'>
                            <MapContainer center={center} zoom={13} scrollWheelZoom={true}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker
                                    draggable={true}
                                    eventHandlers={eventHandlers}
                                    position={position}
                                    ref={markerRef}
                                    icon={VenueLocationIcon}
                                >
                                    <Popup minWidth={60} >
                                        <span
                                            style={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {location}
                                        </span>
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    )
}

const Checkboxes = ({
    field,
    values,
    setFieldValue,
}) => {
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const getData = async () => {
        await DefaultRequestAuth(
            'get',
            field.urlApi,
            setList,
            'data',
            dispatch,
        )

        // setList(res.data)
        setLoading(false)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleSelectAll = () => {
        if (values[field.name]?.length > 0)
            setFieldValue(field.name, []);
        else
            setFieldValue(field.name, list?.data?.map(l => l.id + ''));
    };

    return (
        <div className='col-12 ' >
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header className='p-0'>{field.title}</Accordion.Header>
                    <Accordion.Body className=''>
                        <div className='text-right'>
                            <button
                                type='button'
                                onClick={handleSelectAll}
                                className='text-sm btn'
                            >
                                {
                                    // values[field.name]?.length === list.length && list?.length > 0 ?
                                    values[field.name]?.length > 0 ?
                                        <>
                                            <i className='fa-solid fa-square-check text-yellow-600' /> Desmarcar todo
                                        </>
                                        :
                                        <>
                                            <i className='fa-regular fa-square dark:text-gray-100 text-gray-700' /> Seleccionar todo
                                        </>
                                }
                            </button>
                        </div>
                        <div className='text-center fw-bold mb-2 text-sm text-secondary'>{field.label}</div>
                        <div className='row gap-2'>
                            {
                                list?.data?.map((item, index) => (
                                    // <div
                                    //     className='select-none col-sm text-lg'
                                    // >
                                    <label
                                        key={index}
                                        htmlFor={item.nombre}
                                        className='rounded-lg d-flex items-center border border-secondary select-none col-sm text-lg'
                                    >
                                        <Field
                                            type='checkbox'
                                            name={field.name}
                                            id={item.nombre}
                                            className='form-check text-lg '
                                            value={item.id + ''}
                                            disabled={item?.permanent}
                                        />
                                        <span className='m-2 text-sm fw-semibold text-secondary' style={{ display: 'flex', alignItems: 'center' }}>
                                            {item[field.indexLabel]}
                                        </span>
                                    </label>
                                    // </div>
                                ))
                            }
                        </div>
                        {
                            !loading && list.length === 0 &&
                            <div className='text-center text-sm mt-3 text-gray-500 dark:text-gray-400'>
                                (Lista vacía, no se encuentran opciones disponibles)
                            </div>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion >
        </div >
    )
}

const SelectSearchDetailCard = ({
    field,
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
}) => {
    const [options, setOptions] = useState([])
    const [list, setList] = useState([])
    const [extraData, setExtraData] = useState(null)
    const dispatch = useDispatch()

    const getData = async () => {
        const res = await DefaultRequestAuth(
            'get',
            field?.urlApi,
            setList,
            'data',
            dispatch,
        )

        const opt = res?.data.map(item => ({
            value: item.id.toString(),
            label: item[field?.labelDescription]
        }))
        setOptions(opt)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (field?.setAction != null && field?.setAction !== undefined) {
            field?.setAction(setFieldValue, extraData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraData])

    return (
        <>
            <div className='col-sm-6 my-2'>
                <div className='rounded p-1' style={{ background: 'rgba(230,230,230)' }}>
                    <label htmlFor={field?.name} className='block mb-2 text-sm font-semibold text-secondary'>
                        {field?.label}
                        {field?.required && <RequiredPick />}
                    </label>
                    <Field name={field?.name}>
                        {() => (
                            <ReactSelect
                                id={field?.name}
                                defaultValue={field?.defaultValue}
                                placeholder={field?.placeholder}
                                options={options}
                                name={field?.name}
                                isClearable={true}
                                onChange={(e) => {
                                    handleChange(field?.name, e)
                                    setFieldValue(field?.name, e?.value ?? '')
                                    setExtraData(list?.data?.find(l => (l.id + '') === e?.value))
                                    setFieldValue(field?.nameNumber, list && list?.data?.find(l => (l.id + '') === e?.value) ? list?.data?.find(l => (l.id + '') === e?.value)[field?.nameNumber] : 0)
                                    setFieldValue(field?.nameNumberRestante, list && list?.data?.find(l => (l.id + '') === e?.value) ? list?.data?.find(l => (l.id + '') === e?.value)[field?.nameNumber] : 0)
                                    // setFieldValue(field?.nameNumberRestante, 0)
                                    setFieldValue(field?.alterField, 0)
                                }}
                                // styles={colourStyles}
                                autoFocus={field?.autoFocus}
                                maxMenuHeight={150}
                                noOptionsMessage={() => 'No hay opciones disponibles'}
                                className='fw-bold'
                            />
                        )}
                    </Field>
                    {
                        field?.locked ?
                            <ErrorLockLabel
                                name={field?.name}
                                errors={errors}
                            />
                            :
                            <ErrorLabel
                                name={field?.name}
                                errors={errors}
                                touched={touched}
                            />
                    }
                    <div className='text-sm dark:text-gray-400 text-gray-700 p-1'>
                        {
                            extraData
                            &&
                            field?.infoTags?.map((info, index) => (
                                <div
                                    key={index}
                                >
                                    <span className='fw-semibold'>{info.label}: </span>
                                    <span
                                        className={`fw-bold text-primary`}>
                                        {
                                            info.isDateValue ?
                                                formatDefaultDate(extraData[info?.data])
                                                :
                                                extraData[info?.data]
                                        }
                                    </span>
                                </div>
                            ))
                        }
                        {
                            extraData &&
                            <div>
                                <span className='fw-semibold dark:text-gray-400 text-gray-600'>{field?.labelNumberRestante}: </span>
                                <span
                                    className={`${(values[field?.nameNumberRestante] <= 0 ? 'fw-bold text-danger' : 'fw-bold text-success')}`}>
                                    {values[field?.nameNumberRestante]}
                                </span>
                            </div>
                        }
                    </div>
                    {
                        !extraData &&
                        <AlertLockDescription
                            message={field?.exclamationDefault}
                        />
                    }
                    {
                        parseFloat(values[field?.nameNumberRestante]) < 0 && parseFloat(values[field?.nameNumber]) > 0 &&
                        <ErrorLockDescription
                            message={field?.exclamationRestante}
                        />
                    }
                    {
                        extraData && parseFloat(values[field?.nameNumber]) < 1 &&
                        <ErrorLockDescription
                            message={field?.exclamationRestante}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export const RequiredPick = () => {
    return (
        <span className='font-bold text-danger'>
            *
        </span>
    )
}

const ErrorLabel = ({ name, errors, touched }) => {
    return (
        errors[name] && touched[name] ? (
            <span className='text-xs text-red-600 dark:text-red-500 font-semibold absolute'>{errors[name]}</span>
        ) : null
    )
}

const ErrorLockLabel = ({ name, errors, submitForm }) => {
    return (
        errors[name] || submitForm ? (
            <span className='text-xs text-red-600 dark:text-red-500 font-semibold absolute'>{errors[name]}</span>
        ) : null
    )
}

const ErrorLockDescription = ({ message }) => {
    return (
        <div className='p-1 my-1 rounded bg-danger text-xs'><i className='fa-solid fa-triangle-exclamation' /> {message}</div>
    )
}

const AlertLockDescription = ({ message }) => {
    return (
        <div className='p-1 my-1 rounded bg-warning text-xs'><i className='fa-solid fa-circle-exclamation' /> {message}</div>
    )
}